<template>
	<div>
		<!-- Loader -->
		<div
			v-if="loading"
			class="flex justify-center"
		>
			<i class="fas fa-spinner fa-spin fa-2x color-blue"></i>
		</div>

		<main
			v-if="!loading"
			class="container-wide"
		>
			<div v-if="agreement.isArchived">
				<div class="mb-20 color-red text-center">Hendan avtalan er arkiverað.</div>
			</div>
			<div class="flex space-between mb-30">
				<div>
					<div class="mb-20">
						<div class="flex align-center mb-10">
							<router-link
								:to="{ name: 'CustomerShow', params: { id: agreement.customer.id || 1 } }"
								v-if="agreement.customer.name"
							>
								<h1>
									{{ agreement.customer.name }}
								</h1>
							</router-link>

							<a
								:href="agreementLink"
								target="_blank"
								class="ml-10"
								><i class="fas fa-link"></i
							></a>
						</div>

						<div class="color-grey-700">
							{{ type }}

							&nbsp;

							<span
								v-if="agreement.isShortTermAgreement"
								class="mt-5"
								>(Vikuskiftisleiga)</span
							>
						</div>

						<div
							v-if="agreement.hasBeenApprovedByUser"
							class="color-green mt-10"
						>
							<i class="fas fa-check"></i>

							Góðkend av kunda<span v-if="agreement.approvedByUserAt"
								>, tann {{ new Date(agreement.approvedByUserAt).format('%d.%m.%y kl. %H:%M') }}</span
							>
						</div>
					</div>

					<div
						v-if="!agreement.isArchived"
						class="mb-5"
					>
						<label class="inline-flex align-center cursor-pointer">
							<div
								class="switch"
								:class="{ active: active }"
							>
								<input
									type="checkbox"
									v-model="active"
								/>

								<div></div>
							</div>

							<span class="ml-5">Virkin</span>
						</label>
					</div>
				</div>

				<div class="flex">
					<div
						class="mr-10"
						title="Einnýtisleinkja"
					>
						<div
							class="btn-round cursor-pointer"
							:class="{ 'background-red hover:background-red': !agreement.agreementLink || !agreement.agreementLink.active }"
							@click="createLink(agreement)"
						>
							<i
								class="fas"
								:class="{
									'fa-unlink': !agreement.agreementLink || !agreement.agreementLink.active,
									'fa-link': agreement.agreementLink && agreement.agreementLink.active,
								}"
							></i>
						</div>
					</div>

					<div
						class="mr-10"
						title="Broyt sáttmála"
					>
						<router-link
							class="btn-round far fa-edit"
							:to="{ name: 'AgreementEdit', params: { id: agreement.id } }"
						></router-link>
					</div>

					<Mail
						:agreement="agreement"
						:customer="agreement.customer"
						:files="files"
						type="Sáttmála"
					/>
				</div>
			</div>

			<LinkOverlay
				:visible="linkOverlayVisible"
				:agreement="agreement"
				@close="linkOverlayVisible = false"
			/>

			<hr class="mb-30" />

			<div class="mb-40">
				<div class="grid grid-3-5">
					<div>
						<h2 class="mb-20">Stig</h2>

						<p
							v-if="steps.length == 0"
							class="color-grey"
						>
							Ongin leistur er valdur til hesa avtaluna
						</p>

						<ul class="steps">
							<li
								v-for="(step, index) in steps"
								:key="step.name"
							>
								<label
									class="group cursor-pointer text-normal"
									:for="step.name + '_' + index"
									@click.stop="toggleDone(step)"
								>
									<input
										type="checkbox"
										v-model="step.done"
									/>

									<span>
										{{ step.name }}
									</span>

									<Tooltip
										v-if="step.completedAt || step.completedBy"
										:no-icon="true"
									>
										<div
											v-if="step.completedAt"
											class="mb-10"
										>
											{{ new Date(step.completedAt).format('%d/%m/%y %H:%M') }}
										</div>

										<div v-if="step.completedBy">{{ `${step.completedBy.firstName} ${step.completedBy.lastName}` }} hevur avgreitt.</div>
									</Tooltip>
								</label>
							</li>
						</ul>
					</div>

					<div class="add">
						<div class="flex space-between">
							<h2>Loggur</h2>

							<div
								class="btn-round small"
								@click="logOverlay.visible = true"
							>
								<i class="fas fa-plus"></i>
							</div>
						</div>

						<div
							v-if="!logs || !logs.length"
							class="color-grey my-20"
						>
							Ongin loggur varð funnin
						</div>

						<div style="max-height: 448px; overflow-y: auto">
							<table v-if="logs && logs.length">
								<thead>
									<th>Viðmerking</th>

									<th>Viðmerkjari</th>

									<th>Dato</th>

									<th></th>
								</thead>

								<tbody>
									<tr
										v-for="(log, index) in logs"
										:key="`log-${index}`"
										@click="
											() => {
												logOverlay.log = log;
												logOverlay.visible = true;
											}
										"
										class="cursor-pointer"
									>
										<td>
											<div class="line-clamp-1">
												{{ log.comments }}
											</div>
										</td>

										<td style="white-space: pre">{{ log.userName ? log.userName : '[KUNDIN]' }}</td>

										<td style="white-space: pre">
											{{ new Date(log.createdAt).format('%d/%m/%y %H:%M') }}
										</td>

										<td class="right">
											<div>
												<i
													class="fas fa-trash-alt hover:color-red cursor-pointer"
													title="Strika"
													@click.stop.prevent="deleteLog(log.id)"
												></i>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

			<hr class="mb-30" />

			<div class="mb-30">
				<div class="flex space-between">
					<h2>Konta</h2>
				</div>

				<div
					v-if="!agreement.bankAccount"
					class="color-grey my-30"
				>
					Ongin konta varð funnin
				</div>

				<div
					v-if="agreement.bankAccount"
					class="mb-50"
				>
					<table>
						<thead>
							<th>Reg nr.</th>

							<th>Konto nr.</th>

							<th>Bankaváttan</th>

							<th>Seinast umbiðin</th>

							<th></th>
						</thead>

						<tbody>
							<tr>
								<td>{{ agreement.bankAccount.registrationNumber }}</td>

								<td>{{ agreement.bankAccount.accountNumber }}</td>

								<td>
									<!-- If no verification file -->
									<div
										v-if="!accountVerificationFile"
										class="flex items-center"
									>
										<div
											@click="downloadVerificationFile(agreement.bankAccount)"
											class="hover:color-blue cursor-pointer mr-20"
										>
											<i class="fas fa-file-download"></i>

											Tak skjal niður
										</div>

										<div
											@click="openVerificationFileInput"
											class="hover:color-blue cursor-pointer mr-20"
										>
											<i class="fas fa-upload"></i>

											Legg undirskrivað skjal upp
										</div>

										<div
											@click="openVerificationOverlay(agreement.bankAccount)"
											class="hover:color-blue cursor-pointer"
											:class="{ disabled: accountVerificationOverlay.requested }"
										>
											<i class="fas fa-check mr-5"></i>

											<span>Umbið váttan</span>
										</div>
									</div>

									<!-- If verification file -->
									<div
										v-if="accountVerificationFile"
										class="flex items-center"
									>
										<div
											@click="downloadVerificationFileWithSignature(agreement.bankAccount)"
											class="hover:color-blue cursor-pointer mr-20"
										>
											<i class="fas fa-file-download"></i>

											Tak skjal niður
										</div>

										<div
											@click="deleteFile('accountverificationfile', agreement.bankAccount.id)"
											class="hover:color-red cursor-pointer mr-20"
										>
											<i class="fas fa-trash-alt"></i>

											Strika skjal
										</div>

										<div
											@click="openVerificationOverlay(agreement.bankAccount)"
											class="hover:color-blue cursor-pointer"
											:class="{ disabled: accountVerificationOverlay.requested }"
										>
											<i class="fas fa-check mr-5"></i>

											<span>Umbið váttan</span>
										</div>
									</div>
								</td>

								<td>
									<div v-if="agreement.bankAccount.verificationRequestedAt">
										{{ new Date(agreement.bankAccount.verificationRequestedAt).format('%d/%m/%y kl. %H:%M') }}
									</div>

									<div v-if="!agreement.bankAccount.verificationRequestedAt">-</div>
								</td>

								<td class="text-right">
									<i
										class="fas fa-trash-alt hover:color-red cursor-pointer"
										title="Strika konto"
										@click="deleteAccount(agreement.bankAccount)"
									></i>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<VOverlay
				:visible="accountVerificationOverlay.visible"
				@close="
					accountVerificationOverlay.visible = false;
					accountVerificationOverlay.form.email = '';
					accountVerificationOverlay.accountId = null;
				"
			>
				<div class="mb-30">
					<h1 class="mb-5">Umbið váttan</h1>

					<p class="color-grey">Umbið eina bankaváttan.</p>
				</div>

				<form
					@submit.prevent="
						requestBankVerification();

						accountVerificationOverlay.visible = false;
						accountVerificationOverlay.form.email = '';
						accountVerificationOverlay.requested = true;
					"
				>
					<label class="block mb-20">
						<div class="mb-10">Teldupostbústaður</div>

						<input
							v-model="accountVerificationOverlay.form.email"
							ref="accountVerificationOverlayInput"
							type="text"
						/>
					</label>

					<div class="flex justify-end">
						<button
							href="#"
							:disabled="!accountVerificationOverlay.form.email"
							class="btn"
							:class="{ disabled: !accountVerificationOverlay.form.email }"
						>
							Umbið
						</button>
					</div>
				</form>
			</VOverlay>

			<a
				ref="anchor"
				href="#"
				download="true"
			></a>

			<input
				type="file"
				ref="verificationFileInput"
				class="display-none"
				data-id=""
				accept="application/pdf"
				@change="uploadVerificationFile"
			/>

			<a
				href="#"
				ref="verificationFileLink"
				class="display-none"
				target="_blank"
			/>

			<div v-if="agreement.type && agreement.type.name != 'KYC'">
				<hr class="mb-30" />

				<div class="mb-30 add">
					<div class="flex space-between">
						<h2>Terminalar</h2>

						<div
							class="btn-round small"
							@click="addTerminalOverlay.visible = true"
						>
							<i class="fas fa-plus"></i>
						</div>
					</div>

					<div
						v-if="!agreement.agreementTerminals || !agreement.agreementTerminals.length"
						class="color-grey my-20"
					>
						Ongir terminalar vórðu funnir
					</div>

					<table v-if="agreement.agreementTerminals && agreement.agreementTerminals.length">
						<thead>
							<th>Verifone SR</th>

							<th>Verifone NR</th>

							<th>Model</th>

							<th>Frá</th>

							<th>Til</th>

							<th>&nbsp;</th>
						</thead>

						<tbody>
							<tr
								v-for="(agreementTerminal, index) in agreement.agreementTerminals"
								:key="`terminal-${index}`"
							>
								<td :class="{ 'color-grey-200': agreementTerminal.terminal.deletedAt }">
									<router-link
										:to="{ name: 'TerminalShow', params: { id: agreementTerminal.terminal.id } }"
										class="hover:color-blue"
									>
										{{ agreementTerminal.terminal.verifoneSerialnumber }}
									</router-link>

									<i
										v-if="agreementTerminal.terminal.lease"
										class="fas fa-info-circle color-blue ml-5"
										title="Leiguterminalur"
									></i>

									<span
										v-if="agreementTerminal.terminal.isTemporarilyOnAgreement"
										title="Fyribils á avtalu"
										class="ml-5"
									>
										<i class="fas fa-stopwatch color-green"> </i>
									</span>

									<span
										v-if="agreementTerminal.terminal.isSupport"
										title="Support terminalur"
										class="ml-5"
									>
										<i class="fas fa-life-ring color-red"></i>
									</span>
								</td>

								<td :class="{ 'color-grey-200': agreementTerminal.terminal.deletedAt }">
									<router-link
										:to="{ name: 'TerminalShow', params: { id: agreementTerminal.terminal.id } }"
										class="hover:color-blue"
									>
										{{ agreementTerminal.terminal.verifoneNumber }}
									</router-link>
								</td>

								<td :class="{ 'color-grey-200': agreementTerminal.terminal.deletedAt }">{{ agreementTerminal.terminal.modelName }}</td>

								<td :class="{ 'color-grey-200': agreementTerminal.terminal.deletedAt }">
									{{ agreementTerminal.from ? new Date(agreementTerminal.from).format('%d/%m/%y %H:%M') : '-' }}
								</td>

								<td :class="{ 'color-grey-200': agreementTerminal.terminal.deletedAt }">
									{{ agreementTerminal.to ? new Date(agreementTerminal.to).format('%d/%m/%y %H:%M') : '-' }}
								</td>

								<td class="right">
									<div v-if="!agreementTerminal.terminal.deletedAt">
										<i
											class="fas fa-random cursor-pointer mr-20 hover:color-blue"
											title="Být um"
											@click="swapTerminal(agreementTerminal.terminal)"
											v-if="!agreementTerminal.to || new Date(agreementTerminal.to) > new Date()"
										></i>

										<i
											class="fas fa-unlink hover:color-red cursor-pointer mr-20"
											title="Strika tilknýti"
											@click="confirmDeleteTerminal(agreementTerminal)"
										></i>

										<i
											class="far fa-calendar-alt hover:color-blue cursor-pointer mr-20"
											title="Rætta"
											@click="editAgreementTerminalPeriod(agreementTerminal)"
										></i>

										<router-link
											:to="{ name: 'TerminalShow', params: { id: agreementTerminal.terminal.id } }"
											title="Vís"
										>
											<i class="far fa-eye cursor-pointer color-grey hover:color-blue"></i>
										</router-link>
									</div>

									<div v-if="agreementTerminal.terminal.deletedAt">
										<span class="color-grey-200">Er strikaður</span>
									</div>
								</td>
							</tr>
						</tbody>
					</table>

					<AddTerminalOverlay
						:agreement="agreement"
						:visible="addTerminalOverlay.visible"
						@close="addTerminalOverlay.visible = false"
					/>

					<EditTerminalPeriodOverlay
						:agreementTerminal="editAgreementTerminal.agreementTerminal"
						:visible="editAgreementTerminal.visible"
						@close="editAgreementTerminal.visible = false"
					/>

					<SwapTerminalOverlay
						:agreement="agreement"
						:oldTerminal="swap.terminal"
						:visible="swap.visible"
						@close="swap.visible = false"
						@updateAgreementTerminals="updateAgreementTerminals"
					/>
				</div>
			</div>

			<hr class="mb-30" />

			<div class="mb-40 add">
				<div class="flex space-between">
					<h2>Skjøl</h2>

					<div class="btn-round small">
						<i class="fas fa-plus"></i>

						<input
							type="file"
							id="file"
							ref="file"
							@change="uploadFile"
						/>
					</div>
				</div>

				<div
					v-if="!files || !files.length"
					class="color-grey my-20"
				>
					Ongi skjøl vórðu funnin
				</div>

				<table v-if="files && files.length">
					<thead>
						<th>Navn</th>

						<th>Uploadað</th>

						<th>Brúkari</th>

						<th>Stødd</th>

						<th>Vátta</th>

						<th>Váttað av</th>

						<th></th>
					</thead>

					<tbody>
						<tr
							v-for="(file, index) in files"
							:key="`file-${index}`"
						>
							<td style="max-width: 300px">
								<div
									class="line-clamp-1 hover:color-blue cursor-pointer"
									:title="file.original_name"
									@click="downloadFile(file)"
								>
									<i
										class="far fa-file-word color-blue"
										v-if="file.extension == 'docx'"
									></i>
									<i
										class="far fa-file-excel color-blue"
										v-else-if="file.extension == 'xlsx'"
									></i>
									<i
										class="far fa-file-pdf color-blue"
										v-else-if="file.extension == 'pdf'"
									></i>
									<i
										class="far fa-file-image color-blue"
										v-else-if="file.extension == 'png'"
									></i>
									<i
										class="far fa-file-image color-blue"
										v-else-if="file.extension == 'jpg'"
									></i>
									<i
										class="far fa-file-image color-blue"
										v-else-if="file.extension == 'jpeg'"
									></i>
									<i
										class="far fa-file color-blue"
										v-else
									></i>

									<span class="ml-5">
										{{ decodeURI(file.original_name) }}

										<span
											v-if="!file.confirmedAt"
											class="ml-5 color-blue"
											><i
												class="fas fa-exclamation-circle"
												title="Nýtt skjal"
											></i
										></span>
									</span>
								</div>
							</td>

							<td>
								{{ new Date(file.createdAt).format('%d/%m/%y %H:%M') }}
							</td>

							<td>
								{{ file.user ? file.user.firstName : '[KUNDIN]' }}
							</td>

							<td>{{ (file.size / 1024 / 1024).toFixed(2) }} MB</td>

							<td>
								<input
									type="checkbox"
									@change="confirmFile($event, file)"
									:checked="!!file.confirmedAt"
									class="cursor-pointer"
								/>
							</td>

							<td>{{ getConfirmedBy(file) }}</td>

							<td class="right">
								<div>
									<i
										class="fas fa-file-download mr-20 hover:color-blue cursor-pointer"
										title="Tak niður"
										@click="downloadFile(file)"
									></i>

									<i
										class="fas fa-trash-alt hover:color-red cursor-pointer"
										title="Strika"
										@click="confirmDeleteFile(file)"
									></i>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<hr class="mb-30" />

			<div class="my-30">
				<div
					v-if="!agreement.isArchived"
					@click="archiveAgreement(true)"
					class="btn inline-block background-red"
				>
					Arkivera avtalu
				</div>

				<div
					v-if="agreement.isArchived"
					@click="archiveAgreement(false)"
					class="btn inline-block"
				>
					Virkja avtalu
				</div>
			</div>

			<LogOverlay
				v-model="logOverlay.log"
				:agreementId="agreement.id"
				:visible="logOverlay.visible"
				@create="createLog"
				@close="
					() => {
						logOverlay.visible = false;
						logOverlay.log = null;
					}
				"
			/>
		</main>
	</div>
</template>

<script>
import axios from 'axios';

import AddTerminalOverlay from '@/components/AddTerminalOverlay.vue';
import SwapTerminalOverlay from '@/components/SwapTerminalOverlay.vue';
import LogOverlay from '@/components/LogOverlay.vue';
import LinkOverlay from '@/components/LinkOverlay.vue';
import EditTerminalPeriodOverlay from '../components/EditTerminalPeriodOverlay.vue';
import VOverlay from '../components/VOverlay.vue';
import Mail from '../components/Mail.vue';
import Tooltip from '../components/Tooltip.vue';

export default {
	name: 'AgreementShow',

	components: {
		AddTerminalOverlay,
		LinkOverlay,
		SwapTerminalOverlay,
		EditTerminalPeriodOverlay,
		LogOverlay,
		VOverlay,
		Mail,
		Tooltip,
	},

	data() {
		return {
			loading: true,

			steps: [],

			active: null,

			initialActive: false,

			linkOverlayVisible: false,

			addTerminalOverlay: {
				visible: false,
			},

			editAgreementTerminal: {
				visible: false,

				agreementTerminal: null,
			},

			accountVerificationOverlay: {
				visible: false,
				accountId: null,
				requested: false,

				form: {
					email: '',
				},
			},

			dateFormat: '%d. %mn %y kl. %H:%M',

			file: null,

			files: [],

			swap: {
				visible: false,

				terminal: null,
			},

			accountVerificationFile: null,

			agreement: {
				id: 0,

				customer: {},

				agreementTerminals: [],

				agreementLink: {
					token: null,
				},

				bankAccount: {},
			},

			logs: [],
			logOverlay: {
				visible: false,
				log: {},
			},
		};
	},

	async mounted() {
		const agreementId = this.$route.params.id;

		const promises = [];

		// Get Agreement Data
		promises.push(axios.get(`/agreements/${agreementId}`));

		// Get Agreement Files
		promises.push(axios.get(`/agreements/${agreementId}/files`));

		// Get Agreement Terminals
		promises.push(axios.get(`/agreements/${agreementId}/terminals`));

		// Get Agreement Steps
		promises.push(axios.get(`/agreements/${agreementId}/steps`));

		Promise.all(promises)
			.then((responses) => {
				// Get Agreement
				this.agreement = responses[0].data;

				// Get Bank Account Verification File from agreement (if it exists)
				this.accountVerificationFile = this.agreement?.bankAccount?.accountVerificationFile;

				// Is the Agreement 'active'?
				this.active = this.agreement.active;

				// Get Logs from agreement
				this.logs = this.agreement.logs;
				// Sort logs by date
				this.logs.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

				// Get Files
				this.files = responses[1].data;

				// Sort Files by date
				this.files.sort((a, b) => {
					return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
				});

				// Get Terminals
				const agreementTerminals = responses[2].data;

				// Add terminals to agreement
				this.agreement.agreementTerminals = agreementTerminals.filter((agreementTerminal) => !!agreementTerminal.terminal);

				// Get Steps
				this.steps = responses[3].data;
				this.steps.sort((a, b) => a.index - b.index);
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
			});
	},

	computed: {
		startDate() {
			if (!this.agreement.from) {
				return '';
			}

			return new Date(this.agreement.from).format(this.dateFormat);
		},

		endDate() {
			if (!this.agreement.to) {
				return '';
			}

			return new Date(this.agreement.to).format(this.dateFormat);
		},

		type() {
			if (!this.agreement.type) {
				return;
			}

			return this.agreement.type.name;
		},

		agreementLink() {
			return `${process.env.VUE_APP_TERMINAL_CLIENT_URL}/client?token=${this.agreement?.agreementLink?.token}`;
		},
	},

	watch: {
		active: function (value) {
			if (!this.initialActive) {
				this.initialActive = true;

				return;
			}

			axios
				.post(`/agreements/${this.$route.params.id}/toggle-active`)
				.then((response) => {
					this.agreement.active = response.data.active;
				})
				.catch((error) => {
					console.error('Unable to toggle active on agreement', error.response);
				});
		},

		'agreement.isArchived': function (archived) {
			axios
				.post(`/agreements/${this.agreement.id}/set-archived`, {
					archived,
				})
				.catch((error) => {
					console.log('Error toggling archived', error);
				});
		},
	},

	methods: {
		createLink(agreement) {
			this.linkOverlayVisible = true;
			this.link = `${process.env.VUE_APP_TERMINAL_CLIENT_URL}/client?token=${agreement.agreementLink?.token}`;
			this.agreement = agreement;
		},

		getConfirmedBy(file) {
			if (!file.confirmedBy) {
				return '-';
			}

			return `${file.confirmedBy.firstName} ${file.confirmedBy.lastName}`;
		},

		toggleDone(step) {
			axios
				.patch(`/steps/${step.id}/toggle-done`)
				.then((response) => {
					const index = this.steps.findIndex((item) => item.id === step.id);

					this.steps[index].done = response.data.done;
					this.steps[index].completedAt = response.data.completedAt;
					this.steps[index].completedBy = response.data.completedBy;
				})
				.catch((error) => {
					console.log('Toggle step error', error);
				});
		},

		confirmDeleteTerminal(agreementTerminal) {
			const confirm_delete = confirm('Vilt tú veruliga strika terminalin?');

			if (confirm_delete) {
				axios
					.delete(`/agreements/agreement-terminal/${agreementTerminal.id}`)
					.then(() => {
						this.agreement.agreementTerminals = this.agreement.agreementTerminals.filter(
							(terminal) => terminal.terminalId != agreementTerminal.terminalId
						);
					})
					.catch((error) => {
						console.error(error);
					});
			}
		},

		editAgreementTerminalPeriod(agreementTerminal) {
			this.editAgreementTerminal.agreementTerminal = agreementTerminal;

			this.editAgreementTerminal.visible = true;
		},

		swapTerminal(terminal) {
			this.swap.visible = true;

			this.swap.terminal = terminal;
		},

		updateAgreementTerminals(agreementTerminals) {
			this.agreement.agreementTerminals = agreementTerminals;

			this.swap.visible = false;
		},

		confirmDeleteFile(file) {
			const confirm_delete = confirm('Ert tú vís/ur?');

			if (confirm_delete) {
				axios
					.delete(`/files/${file.uuid}`)
					.then(() => {
						this.files.splice(
							this.files.findIndex((item) => item.uuid == file.uuid),
							1
						);
					})
					.catch((error) => {
						console.error(error.response);
					});
			}
		},

		uploadFile() {
			const formData = new FormData();

			const file = this.$refs.file.files[0];
			const fileName = `${encodeURI(file.name)}`;

			formData.append('file', new File([file], fileName, { type: file.type }));
			formData.append('agreement_uuid', this.agreement.uuid);
			formData.append('customer_uuid', this.agreement.customer.uuid);

			axios
				.post(`/files`, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					this.files = [response.data, ...this.files];
				})
				.catch((error) => {
					console.error(error.response);
				});
		},

		openVerificationFileInput() {
			this.$refs.verificationFileInput.click();
		},

		downloadFile(file) {
			axios.get(`/files/${file.uuid}/url`).then((response) => {
				const link = document.createElement('a');
				link.href = response.data;
				link.setAttribute('download', file.original_name);

				document.body.appendChild(link);

				link.click();
			});
		},

		createLog(log) {
			this.logs = [log, ...this.logs];

			this.logOverlay.visible = false;
		},

		deleteLog(logId) {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			axios
				.delete(`/agreements/${this.agreement.id}/log/${logId}`)
				.then(() => {
					this.logs = this.logs.filter((log) => log.id != logId);
				})
				.catch((error) => {
					console.log(error);
				});
		},

		uploadVerificationFile(e) {
			const file = e.target.files[0];

			const formData = new FormData();

			formData.append('file', file);
			formData.append('agreement_uuid', this.agreement.uuid);

			this.loading = true;

			axios
				.post(`/bank-accounts/${this.agreement.bankAccount.id}/accountverificationfile`, formData)
				.then((response) => {
					this.loading = false;

					this.accountVerificationFile = response.data.accountVerificationFile;
				})
				.catch((error) => console.log(error));
		},

		deleteAccount(account) {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			axios
				.delete(`/bank-accounts/${account.id}`)
				.then(() => {
					this.agreement.bankAccount = null;
				})
				.catch((error) => {
					console.log('Error deleting bank account', error);
				});
		},

		archiveAgreement(isArchived) {
			if (!confirm('Ert tú vísur?')) {
				return;
			}

			this.agreement.isArchived = isArchived;

			// Yikes! Ein "watchari" tekur sær av hesum.
		},

		downloadVerificationFile(account) {
			axios
				.get(`/bank-accounts/bank-verification/${account.uuid}/get-file`, {
					responseType: 'blob',
				})
				.then((response) => {
					const url = URL.createObjectURL(response.data);

					this.$refs.anchor.download = 'verify_ownership.pdf';
					this.$refs.anchor.href = url;

					this.$nextTick(() => {
						this.$refs.anchor.click();
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},

		downloadVerificationFileWithSignature(account) {
			axios
				.get(`/bank-accounts/${account.id}/accountverificationfile`)
				.then((response) => {
					const url = response.data.url;

					window.open(url, '_blank').focus();
				})
				.catch((error) => console.log(error));
		},

		openVerificationOverlay(account) {
			this.accountVerificationOverlay.visible = true;
			this.accountVerificationOverlay.accountId = this.agreement.bankAccount.id;

			if (account.registrationNumber == '6460') {
				this.accountVerificationOverlay.form.email = 'kundi@banknordik.fo';
			}

			if (account.registrationNumber == '9181') {
				this.accountVerificationOverlay.form.email = 'banki@betri.fo';
			}

			if (account.registrationNumber == '9865') {
				this.accountVerificationOverlay.form.email = 'ns@ns.fo';
			}

			if (account.registrationNumber == '9870') {
				this.accountVerificationOverlay.form.email = 'ss@ss.fo';
			}

			this.$nextTick(() => {
				this.$refs.accountVerificationOverlayInput.focus();
			});
		},

		requestBankVerification() {
			axios
				.post(`/bank-accounts/${this.accountVerificationOverlay.accountId}/send-verification-email`, {
					email: this.accountVerificationOverlay.form.email,
					agreement_uuid: this.agreement.uuid,
				})
				.then((response) => {
					window.location.reload();
				})
				.catch((error) => {
					console.log(error);
				});
		},

		deleteFile(type, fileId) {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			if (type == 'accountverificationfile') {
				axios
					.delete(`/bank-accounts/${fileId}/accountverificationfile`)
					.then(() => {
						this.accountVerificationFile = null;
					})
					.catch((error) => {
						console.log('Error deleting bank account', error);
					});
			}
		},

		confirmFile(e, file) {
			const checked = e.target.checked;

			axios
				.post(`/files/${file.uuid}/confirm`, {
					confirmed: checked,
				})
				.then((response) => {
					let files = [...this.files];

					const index = files.findIndex((f) => f.id == file.id);

					const fileCopy = { ...files[index] };
					fileCopy.confirmedBy = response.data.confirmedBy;
					fileCopy.confirmedAt = response.data.confirmedAt;

					files.splice(index, 1, fileCopy);

					this.files = files;
				})
				.catch((error) => {
					console.log('Error confirming file', error);
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.steps {
	display: flex;
	flex-direction: column;

	padding: 0;

	list-style: none;

	label {
		display: inline-flex;
		align-items: center;
		font-size: 16px;
		margin-bottom: 12px;

		input {
			margin-right: 8px;
		}
	}
}

.box {
	border-radius: 10px;

	box-shadow: 0 2px 20px rgba(black, 0.05);
}

.btn-round {
	&.small {
		position: relative;

		width: 32px;
		height: 32px;

		cursor: pointer;

		input {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			cursor: pointer;

			opacity: 0;
		}
	}
}

.add {
	.btn-round.small {
		transition: 0.1s opacity ease;

		opacity: 0;
	}

	&:hover {
		.btn-round.small {
			opacity: 1;
		}
	}
}

.line-clamp-1 {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

td {
	.disabled {
		opacity: 0.4;
		pointer-events: none;
	}
}
</style>
