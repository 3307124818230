<template>
	<v-overlay
		:visible="visible"
		@close="$emit('close')"
	>
		<div class="flex space-between align-center mb-50">
			<div>
				<h1 class="mb-10">Legg terminal afturat</h1>

				<p class="color-grey">Legg ein terminal afturat avtaluni.</p>
			</div>

			<div
				class="overlay-close"
				@click.prevent="$emit('close')"
			></div>
		</div>

		<div>
			<!-- Terminal Search -->
			<div v-if="!terminal">
				<label
					class="block mb-10"
					for="search-input-name"
					>Leita eftir terminali</label
				>

				<div
					class="search-input relative"
					:class="{ 'z-100': searchString.length > 0 }"
				>
					<input
						type="text"
						v-model="searchString"
						@keyup="search"
						@keydown.esc.prevent="searchString = ''"
					/>

					<div class="icon-search"></div>

					<div
						v-if="searchString.length > 0 && searchResults.length > 0"
						style="max-height: 320px; overflow-y: auto"
						class="absolute w-100 flex flex-column mt-10 rounded background-white border-light py-10"
						:class="{ 'z-100': searchString.length > 0 }"
					>
						<div
							v-for="(searchResult, index) in searchResults"
							:key="index"
							@click.prevent="applySearchResult(searchResult)"
							class="flex flex-column py-20 px-20 border-bottom hover:background-light-grey-100 last-child:border-none cursor-pointer"
						>
							<p>Verifone # {{ searchResult.verifoneNumber }}</p>

							<div
								v-if="searchResult.phoneNumber || searchResult.psam || searchResult.icc"
								class="mt-5"
							>
								<p
									v-if="searchResult.phoneNumber"
									class="color-grey"
								>
									S/N # {{ searchResult.verifoneSerialnumber }}
								</p>

								<p
									v-if="searchResult.phoneNumber"
									class="color-grey"
								>
									Telefon # {{ searchResult.phoneNumber }}
								</p>

								<p
									v-if="searchResult.psam"
									class="color-grey"
								>
									PSAM: {{ searchResult.psam }}
								</p>

								<p
									v-if="searchResult.icc"
									class="color-grey"
								>
									ICC # {{ searchResult.icc }}
								</p>
							</div>
						</div>
					</div>

					<div
						v-if="searchString.length > 0 && searchResults.length == 0"
						class="absolute w-100 flex flex-column mt-10 rounded background-white border-light py-10"
						:class="{ 'z-100': searchString.length > 0 }"
					>
						<div class="flex flex-column py-10 px-20 border-bottom hover:background-light-grey-100 last-child:border-none">
							<p class="mb-5">Ongin terminalur funnin</p>

							<p>
								Vit hava leitað eftir <strong>{{ searchString }}</strong>
							</p>
						</div>
					</div>
				</div>
			</div>

			<!-- Display Terminal -->
			<div v-if="terminal">
				<div class="flex space-between p-20 border rounded">
					<span>
						Verifone nummar:

						<strong>
							{{ terminal.verifoneNumber }}
						</strong>
					</span>

					<div
						@click="terminal = null"
						title="Vel ein annan"
						class="hover:color-red cursor-pointer"
					>
						<i class="fas fa-trash"></i>
					</div>
				</div>
			</div>

			<div class="grid grid-2 grid-gap-20 mb-20 mt-30">
				<v-date-picker
					v-model="from"
					mode="dateTime"
					is24hr
					:minute-increment="5"
				>
					<template #default="{ inputValue, inputEvents }">
						<div>
							<label
								class="block mb-10"
								for="from"
								>Frá</label
							>

							<div class="date-input">
								<input
									type="text"
									:value="inputValue"
									v-on="inputEvents"
								/>

								<div
									class="date-input-icon-now"
									title="Nú"
									@click="from = new Date()"
								>
									<i class="fa fa-clock"></i>
								</div>

								<div
									class="date-input-icon"
									v-if="!from"
								>
									<i class="fa fa-calendar-alt"></i>
								</div>

								<div
									class="date-input-icon"
									v-if="from"
									@click="from = null"
								>
									<i class="fas fa-times"></i>
								</div>
							</div>
						</div>
					</template>
				</v-date-picker>

				<div>
					<v-date-picker
						v-model="to"
						mode="dateTime"
						is24hr
						:minute-increment="5"
						:min-date="from ? from : new Date()"
					>
						<template #default="{ inputValue, inputEvents }">
							<div>
								<label
									class="block mb-10"
									for="from"
									>Til</label
								>

								<div class="date-input">
									<input
										type="text"
										:value="inputValue"
										v-on="inputEvents"
									/>

									<div
										class="date-input-icon-now"
										title="Nú"
										@click="to = new Date()"
									>
										<i class="fa fa-clock"></i>
									</div>

									<div
										class="date-input-icon"
										v-if="!to"
									>
										<i class="fa fa-calendar-alt"></i>
									</div>

									<div
										class="date-input-icon"
										v-if="to"
										@click="to = null"
									>
										<i class="fas fa-times"></i>
									</div>
								</div>
							</div>
						</template>
					</v-date-picker>

					<div
						v-if="from && from < new Date(agreement.to ? agreement.to : '2099-12-31 23:59')"
						class="text-right mt-5 color-grey"
						style="font-size: 12px"
					>
						<span
							@click="to = new Date(agreement.to ? agreement.to : '2099-12-31 23:59')"
							class="hover:color-blue cursor-pointer"
							>Til avtalan endar</span
						>
					</div>
				</div>
			</div>

			<div class="mb-10">
				<label class="inline-flex align-center cursor-pointer">
					<div
						class="switch"
						:class="{ active: isTemporary }"
					>
						<input
							type="checkbox"
							v-model="isTemporary"
						/>

						<div></div>
					</div>

					<span class="ml-5">Fyribils</span>
				</label>
			</div>

			<div
				v-if="hasOverlap()"
				class="color-red text-center"
			>
				Yvirlappar við

				<router-link
					:to="{ name: 'AgreementShow', params: { id: hasOverlap().id } }"
					class="color-red hover:color-red-600"
					style="font-weight: 700"
				>
					hesa avtaluna
				</router-link>
			</div>

			<div
				v-if="error"
				class="text-center color-red"
			>
				{{ error }}
			</div>

			<div class="flex justify-end mt-30">
				<div
					class="btn inline-block"
					@click="addTerminal"
					:class="{ disabled: !isValid }"
				>
					Legg afturat
				</div>
			</div>
		</div>
	</v-overlay>
</template>

<script>
import axios from 'axios';

import VOverlay from '@/components/VOverlay.vue';

export default {
	name: 'AddTerminalOverlay',

	components: {
		VOverlay,
	},

	props: {
		visible: Boolean,
		agreement: Object,
	},

	data() {
		return {
			terminal: null,

			searchString: '',
			searchResults: [],

			from: null,
			to: null,

			isTemporary: false,

			error: '',
		};
	},

	computed: {
		isValid: function () {
			return this.terminal && !this.hasOverlap() && !!this.from;
		},
	},

	methods: {
		dateOverlaps(startA, endA, startB, endB) {
			if (startA <= startB && startB <= endA) {
				return true;
			}

			if (startA <= endB && endB <= endA) {
				return true;
			}

			if (startB < startA && endA < endB) {
				return true;
			}

			return false;
		},

		hasOverlap() {
			if (!this.terminal?.agreementTerminals) {
				return false;
			}

			if (!this.from) {
				return false;
			}

			const agreementTerminals = this.terminal.agreementTerminals;

			for (const agreementTerminal of agreementTerminals) {
				const [startA, endA, startB, endB] = [
					new Date(this.from).getTime(),
					new Date(this.to || '2100-01-01').getTime(),
					new Date(agreementTerminal.from).getTime(),
					new Date(agreementTerminal.to ?? '2100-01-01').getTime(),
				];

				const overlap = this.dateOverlaps(startA, endA, startB, endB);

				if (overlap) {
					return agreementTerminal.agreement;
				}
			}

			return false;
		},

		search() {
			axios.get(`/terminals/search?q=${this.searchString}`).then((response) => {
				// TODO: Remove console.log()
				console.log(this.terminal);

				const filteredTerminals = response.data.filter((terminal) => this.terminal?.verifoneSerialnumber != terminal.verifoneSerialnumber);

				this.searchResults = filteredTerminals;
			});
		},

		applySearchResult(terminal) {
			axios
				.get(`/terminals/${terminal.id}`)
				.then((response) => {
					this.searchString = '';

					this.terminal = response.data;
				})
				.catch((error) => {
					console.error('Failed to tech terminal', error.response);
				});
		},

		addTerminal() {
			axios
				.post(`/agreements/${this.agreement.id}/agreement-terminal`, {
					terminalId: this.terminal.id,
					from: this.from,
					to: this.to,
				})
				.then(() => {
					this.$router.go();
				})
				.catch((error) => {
					console.error('Failed to add terminal in AddTerminalOverlay', error.response?.data);

					this.error = error.response?.data;

					setTimeout(() => {
						this.error = '';
					}, 5000);
				});

			if (this.isTemporary) {
				axios
					.patch(`/terminals/${this.terminal.id}/toggle-is-temporarily-on-agreement`, {
						isTemporarilyOnAgreement: true,
					})
					.catch(() => {
						console.error('Error trying to toggle temporary on terminal.');
					});
			}
		},

		close() {
			this.$emit('close');

			this.terminal = null;
			this.from = null;
			this.to = null;
		},
	},
};
</script>

<style lang="scss" scoped>
.flex span {
	width: 100%;
}

.date-input {
	position: relative;

	.date-input-icon {
		position: absolute;
		right: 24px;
		top: 50%;
		transform: translateY(-50%);

		font-size: 18px;

		color: var(--color-grey);

		cursor: pointer;
	}

	.date-input-icon-now {
		position: absolute;
		right: 48px;
		top: 50%;
		transform: translateY(-50%);

		font-size: 18px;

		color: var(--color-grey);

		cursor: pointer;
	}
}
</style>
